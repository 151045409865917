import React from 'react'
import clsx from 'clsx'
import * as SelectPrimitive from '@radix-ui/react-select'

import { Button } from '../Button'

import { CheckIcon, ChevronDownIcon } from '../icons'

import styles from './Select.module.css'
import usePortalContainer from '../../helpers/usePortalContainer'

/**
 * Select
 */
type SelectProps = SelectPrimitive.SelectProps & {
  /**
   * Content to render inside the Select
   */
  children: React.ReactNode
  /**
   * Event handler called when the value changes.
   */
  onValueChange: (value: string) => void
  value?: string
  defaultValue?: string
  open?: boolean
  onOpenChange?: (open: boolean) => void
  disabled?: boolean
  defaultOpen?: boolean
}

const Select = (props: SelectProps): JSX.Element => (
  <SelectPrimitive.Root {...props} />
)

Select.displayName = 'Select'

/**
 * Select Trigger
 */
type SelectTriggerProps = Omit<
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>,
  'asChild'
> & {
  /**
   * Placeholder text to display when no value is selected
   */
  placeholder?: string
  /**
   * Content to render inside the Select Trigger
   */
  children?: React.ReactNode
  /**
   * Size of the Select Trigger
   * @default large
   */
  size?: 'medium' | 'large'
}

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ size = 'large', ...props }, forwardedRef) => (
  <SelectPrimitive.Trigger {...props} ref={forwardedRef} asChild>
    {props.children || (
      <Button variant="secondary" size={size} className={styles.trigger}>
        <SelectPrimitive.Value placeholder={props.placeholder} />
        <ChevronDownIcon className="chevron" />
      </Button>
    )}
  </SelectPrimitive.Trigger>
))

SelectTrigger.displayName = 'Select.Trigger'

/**
 * Select Value
 */
type SelectValueProps = Omit<
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Value>,
  'asChild'
>

const SelectValue = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Value>,
  SelectValueProps
>((props, forwardedRef) => (
  <SelectPrimitive.Value {...props} ref={forwardedRef} />
))

SelectValue.displayName = 'Select.Value'

/**
 * Select Content
 */

type SelectContentProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Content
> & {
  /**
   * Additional CSS classes to apply to the Select
   */
  className?: string
  /**
   * Content to render inside the Select Content
   */
  children?: React.ReactNode
}

type SelectContentElement = React.ElementRef<typeof SelectPrimitive.Portal>

const SelectContent = React.forwardRef<
  SelectContentElement,
  SelectContentProps
>(({ className, children, sideOffset = 4, ...props }, forwardedRef) => {
  const portalContainer = usePortalContainer()

  return (
    <SelectPrimitive.Portal container={portalContainer}>
      <SelectPrimitive.Content
        {...props}
        ref={forwardedRef}
        className={clsx(styles.content, className)}
        sideOffset={sideOffset}
        position="popper"
      >
        <SelectPrimitive.Viewport className={clsx(styles.viewport)}>
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  )
})

SelectContent.displayName = 'Select.Content'

/**
 * Select Group
 */

type SelectGroupProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Group
> & {
  /**
   * Additional CSS classes to apply to the Select
   */
  className?: string
  /**
   * Label for the group
   */
  label?: string
  /**
   * Group to render inside the Select Group
   */
  children: React.ReactNode
}

type SelectGroupElement = React.ElementRef<typeof SelectPrimitive.Group>

const SelectGroup = React.forwardRef<SelectGroupElement, SelectGroupProps>(
  ({ className, children, label, ...props }, forwardedRef) => (
    <SelectPrimitive.Group
      {...props}
      ref={forwardedRef}
      className={clsx(styles.group, className)}
    >
      {label && (
        <SelectPrimitive.Label className={clsx(styles.groupLabel, className)}>
          {label}
        </SelectPrimitive.Label>
      )}
      {children}
    </SelectPrimitive.Group>
  ),
)

SelectGroup.displayName = 'Select.Group'

/**
 * Select Separator
 */

type SelectSeparatorProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Separator
> & {
  /**
   * Additional CSS classes to apply to the Select
   */
  className?: string
}

type SelectSeparatorElement = React.ElementRef<typeof SelectPrimitive.Separator>

const SelectSeparator = React.forwardRef<
  SelectSeparatorElement,
  SelectSeparatorProps
>(({ className }, forwardedRef) => (
  <SelectPrimitive.Separator
    ref={forwardedRef}
    className={clsx(styles.separator, className)}
  />
))

SelectSeparator.displayName = 'Select.Separator'

/**
 * Select Item
 */
type SelectItemElement = React.ElementRef<typeof SelectPrimitive.SelectItem>

type SelectItemProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.SelectItem
> & {
  /**
   * Additional CSS classes to apply to the Select
   */
  className?: string
  /**
   * The value of the item
   */
  value: string
  /**
   * Children to render inside the item
   */
  children: React.ReactNode
  /**
   * Whether the item is disabled
   */
  disabled?: boolean
}

const SelectItem = React.forwardRef<SelectItemElement, SelectItemProps>(
  ({ value, children, className, ...props }: SelectItemProps, ref) => {
    return (
      <SelectPrimitive.SelectItem
        value={value}
        className={clsx(styles.item, className)}
        ref={ref}
        {...props}
      >
        <SelectPrimitive.ItemIndicator className={styles.selectItemIndicator}>
          <CheckIcon />
        </SelectPrimitive.ItemIndicator>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </SelectPrimitive.SelectItem>
    )
  },
)

SelectItem.displayName = 'Select.Item'

const SelectObject = Object.assign(Select, {
  Trigger: SelectTrigger,
  Content: SelectContent,
  Group: SelectGroup,
  Separator: SelectSeparator,
  Item: SelectItem,
  Value: SelectValue,
})

export { SelectObject as Select }

export type {
  SelectProps,
  SelectTriggerProps,
  SelectContentProps,
  SelectGroupProps,
  SelectSeparatorProps,
  SelectValueProps,
  SelectItemProps,
}
