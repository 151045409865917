// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AutoResizeUndoIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'auto-resize-undo'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M21.5 17.5C22.0523 17.5 22.5 17.9477 22.5 18.5C22.5 19.0523 22.0523 19.5 21.5 19.5H18.5V22.5C18.5 23.0523 18.0523 23.5 17.5 23.5C16.9477 23.5 16.5 23.0523 16.5 22.5V19.5H7.5C6.70435 19.5 5.94129 19.1839 5.37868 18.6213C4.81607 18.0587 4.5 17.2957 4.5 16.5L4.5 7.5H1.5C0.947714 7.5 0.5 7.05229 0.5 6.5C0.5 5.94772 0.947714 5.5 1.5 5.5L4.5 5.5V2.5C4.5 1.94771 4.94772 1.5 5.5 1.5C6.05228 1.5 6.5 1.94771 6.5 2.5V5.5H9.5C10.0523 5.5 10.5 5.94772 10.5 6.5C10.5 7.05229 10.0523 7.5 9.5 7.5H6.5L6.5 16.5C6.5 16.7652 6.60536 17.0196 6.79289 17.2071C6.98043 17.3946 7.23478 17.5 7.5 17.5H16.5L16.5 14.5C16.5 13.9477 16.9477 13.5 17.5 13.5C18.0523 13.5 18.5 13.9477 18.5 14.5V17.5H21.5Z"
          fill="currentColor"
        />
        <path
          d="M15.0926 3.26191C15.4419 3.61113 15.4419 4.17732 15.0926 4.52654L14.0531 5.56611H17.2831C17.7712 5.56611 18.2545 5.66225 18.7055 5.84905C19.1564 6.03584 19.5662 6.30963 19.9113 6.65478C20.2565 6.99993 20.5303 7.40968 20.7171 7.86064C20.9039 8.3116 21 8.79494 21 9.28306C21 9.77117 20.9039 10.2545 20.7171 10.7055C20.5303 11.1564 20.2565 11.5662 19.9113 11.9113C19.5662 12.2565 19.1564 12.5303 18.7055 12.7171C18.2545 12.9039 17.7712 13 17.2831 13H16.7698C16.276 13 15.8756 12.5996 15.8756 12.1058C15.8756 11.6119 16.276 11.2116 16.7698 11.2116H17.2831C17.5363 11.2116 17.7871 11.1617 18.0211 11.0648C18.255 10.9678 18.4676 10.8258 18.6467 10.6467C18.8258 10.4676 18.9678 10.255 19.0648 10.0211C19.1617 9.78708 19.2116 9.53631 19.2116 9.28306C19.2116 9.0298 19.1617 8.77903 19.0648 8.54505C18.9678 8.31107 18.8258 8.09848 18.6467 7.9194C18.4676 7.74032 18.255 7.59827 18.0211 7.50136C17.7871 7.40444 17.5363 7.35456 17.2831 7.35456H14.0531L15.0926 8.39413C15.4419 8.74335 15.4419 9.30954 15.0926 9.65876C14.7434 10.008 14.1772 10.008 13.828 9.65876L11.2619 7.09265C10.9127 6.74343 10.9127 6.17724 11.2619 5.82802L13.828 3.26191C14.1772 2.9127 14.7434 2.9127 15.0926 3.26191Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
AutoResizeUndoIcon.displayName = 'AutoResizeUndoIcon'
export { AutoResizeUndoIcon }
