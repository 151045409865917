import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { useSplitEnabled } from '@buffer-mono/features'

import SummaryTable from '~/summary-table'
import AverageTable from '~/average-table'
import CompareChart from '~/compare-chart'
import BoostedPostsNotice from '~/boosted-posts-notice'
import Resources from '~/resources'

import { useLockInstagramLoginChannelContent } from '~/hooks/useLockInstagramLoginChannelContent'

import { InstagramFacebookReAuthMessage } from '../InstagramFacebookReAuthMessage'

type RouterParams = {
  params: {
    id?: string | undefined
    channel?: string | undefined
  }
}

type Profile = {
  avatarUrl: string
  disabled: boolean
  id: string
  invalid: boolean
  organizationId: string
  service: string
  serviceId: string
  timezone: string
  username: string
}

const OverviewTab = ({
  match,
  selectedProfile,
}: {
  match: RouterParams
  selectedProfile: Profile
}): JSX.Element => {
  const { currentChannel, lockContent } =
    useLockInstagramLoginChannelContent(selectedProfile)

  if (currentChannel && lockContent) {
    return (
      <InstagramFacebookReAuthMessage
        channelId={currentChannel.id}
        name={currentChannel.name}
      />
    )
  }

  return (
    <Fragment>
      <BoostedPostsNotice />
      {(match.params.channel === 'instagram' ||
        match.params.channel === 'facebook') && (
        <Resources referral="overview" channel={match.params.channel} />
      )}
      <SummaryTable profileService={match.params.channel} />
      <AverageTable />
      <CompareChart />
    </Fragment>
  )
}

export default connect()(OverviewTab)
