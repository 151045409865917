// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AutoResizeOffIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'auto-resize-off'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M21.5 17.5C22.0523 17.5 22.5 17.9477 22.5 18.5C22.5 19.0523 22.0523 19.5 21.5 19.5H18.5V22.5C18.5 23.0523 18.0523 23.5 17.5 23.5C16.9477 23.5 16.5 23.0523 16.5 22.5V19.5H7.5C6.70435 19.5 5.94129 19.1839 5.37868 18.6213C4.81607 18.0587 4.5 17.2957 4.5 16.5L4.5 7.5H1.5C0.947714 7.5 0.5 7.05229 0.5 6.5C0.5 5.94772 0.947714 5.5 1.5 5.5L4.5 5.5V2.5C4.5 1.94771 4.94772 1.5 5.5 1.5C6.05228 1.5 6.5 1.94771 6.5 2.5V5.5H9.5C10.0523 5.5 10.5 5.94772 10.5 6.5C10.5 7.05229 10.0523 7.5 9.5 7.5H6.5L6.5 16.5C6.5 16.7652 6.60536 17.0196 6.79289 17.2071C6.98043 17.3946 7.23478 17.5 7.5 17.5H16.5L16.5 14.5C16.5 13.9477 16.9477 13.5 17.5 13.5C18.0523 13.5 18.5 13.9477 18.5 14.5V17.5H21.5Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7436 5.7753C19.1128 6.18602 19.0792 6.81829 18.6685 7.18752L4.33711 20.9873C3.92639 21.3566 3.29412 21.3229 2.92489 20.9122C2.55566 20.5015 2.58929 19.8692 3 19.5L17.3314 5.70019C17.7421 5.33096 18.3743 5.36459 18.7436 5.7753Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
AutoResizeOffIcon.displayName = 'AutoResizeOffIcon'
export { AutoResizeOffIcon }
