import React from 'react'
import styled, { css } from 'styled-components'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import {
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  ChartTitle,
  ChartStateNoData,
  Helper,
} from '~/shared-components'
import AddReport from '~/add-report'
import Summary from '../Summary'
import Story from '../Story'
import Dropdowns from '../Dropdowns'
import { Previous, Next } from '../Pagination/'
import Stories from '../../Stories'
import { LockIfNotAllowed, STORIES } from '~/account'

const Breakdown = styled.section`
  padding: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
    props.forReport ? '0 0 1.5rem' : '24px 16px'};
  overflow-x: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
    props.forReport ? 'visible' : 'hidden'};
  white-space: nowrap;
`

const Container = styled.div``

const Content = styled.main`
  position: relative;
`

const Pages = styled.div`
  transition: left 0.3s ease-out;
  left: 0%;
  position: relative;
  display: flex;
  align-items: stretch;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'page' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.page > 0 &&
    css`
      left: calc(
        ${(props) =>
          // @ts-expect-error TS(2339) FIXME: Property 'page' does not exist on type 'ThemeProps... Remove this comment to see the full error message
          props.page * -100}%
      );
    `}
`

const Page = styled.div`
  flex: 1 100%;
  position: relative;
  display: flex;
  align-items: top;
  justify-content: flex-start;
  min-width: 100%;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'attribute' implicitly has an 'any' type... Remove this comment to see the full error message
const getSortLabel = (attribute) => {
  const labels = {
    date: 'by date',
    reach: 'with most reach',
    completionRate: 'with highest completion rate',
  }
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return labels[attribute]
}

// @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
export const Title = ({ forReport, state }) => (
  <ChartTitle>
    {forReport
      ? `Stories ${getSortLabel(state.attributeToSortBy)}`
      : 'Stories insights'}
  </ChartTitle>
)

class BreakdownContent extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
    }
    this.previousPage = this.previousPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
  }

  previousPage() {
    this.setState({
      // @ts-expect-error TS(2339) FIXME: Property 'page' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      page: this.state.page - 1,
    })
  }

  nextPage() {
    this.setState({
      // @ts-expect-error TS(2339) FIXME: Property 'page' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      page: this.state.page + 1,
    })
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'metrics' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { metrics, forReport, selectedDay, attributeToSortBy, serviceId } =
      this.props
    // @ts-expect-error TS(2339) FIXME: Property 'page' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { page } = this.state

    // variables needed to build up the pages which contain stories and fillers
    // each page will be full with stories and the remainer are fillers
    let stories = Stories.filterAndSort(metrics, selectedDay, attributeToSortBy)
    stories = forReport ? stories.slice(0, 5) : stories
    if (stories.length === 0) {
      return (
        <Container>
          <Content>
            <ChartStateNoData chartName="stories-breakdown" />
          </Content>
        </Container>
      )
    }
    const storiesTotal = stories.length
    const maxPerPage = 5
    const difference = storiesTotal % maxPerPage
    const fillersTotal = difference > 0 ? maxPerPage - difference : 0
    const totalToRender = storiesTotal + fillersTotal
    const pagesTotal = totalToRender / maxPerPage
    const pagesTotalWidth = pagesTotal * 100 // 100 is 100% for full width
    const onLastPage = pagesTotal - 1 === page || pagesTotal === 0
    const onFirstPage = page === 0

    // build up the stories elements to render, including any remaining fillers
    const storiesToRender = []
    // @ts-expect-error TS(7006) FIXME: Parameter 'story' implicitly has an 'any' type.
    stories.forEach((story) =>
      storiesToRender.push(<Story {...story} serviceId={serviceId} />),
    )
    for (let i = 0; i < fillersTotal; i++) {
      // @ts-expect-error TS(2740) FIXME: Type '{ filler: true; }' is missing the following ... Remove this comment to see the full error message
      storiesToRender.push(<Story filler />)
    }

    // build up the pages elements to render, ready to output
    const pagesToRender = []
    for (let i = 0; i < pagesTotal; i++) {
      const startOfSlice = i * maxPerPage
      const endOfSlice = (i + 1) * maxPerPage
      pagesToRender.push(
        <Page>{storiesToRender.slice(startOfSlice, endOfSlice)}</Page>,
      )
    }

    return (
      <Container>
        <Content>
          {!onFirstPage && <Previous onClick={this.previousPage} />}
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <Breakdown forReport={forReport}>
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <Pages width={pagesTotalWidth} page={page}>
              {pagesToRender}
            </Pages>
          </Breakdown>
          {!onLastPage && <Next onClick={this.nextPage} />}
        </Content>
        <Summary forReport={forReport} stories={metrics} />
      </Container>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
BreakdownContent.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  forReport: PropTypes.bool,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
BreakdownContent.defaultProps = {
  metrics: [],
  loading: false,
  forReport: false,
}

export { BreakdownContent }

class StoriesBreakdown extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.fetch()
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { loading, stories } = this.props

    let content = null
    if (loading) {
      content = (
        <Content>
          {/* @ts-expect-error TS(2741) FIXME: Property 'text' is missing in type '{ noBorder: tr... Remove this comment to see the full error message */}
          <Loading noBorder large />
        </Content>
      )
    } else if (stories.length === 0) {
      content = (
        <Content>
          <ChartStateNoData chartName="stories-breakdown" />
        </Content>
      )
    } else {
      const dates = [
        ...new Set(
          // @ts-expect-error TS(7006) FIXME: Parameter 'story' implicitly has an 'any' type.
          stories.map((story) =>
            dayjs.unix(story.date).startOf('day').valueOf(),
          ),
        ),
      ]
      content = (
        <div>
          <Dropdowns
            // @ts-expect-error TS(2339) FIXME: Property 'sortBy' does not exist on type 'Readonly... Remove this comment to see the full error message
            sortBy={this.props.sortBy}
            // @ts-expect-error TS(2322) FIXME: Type '{ sortBy: any; filterBy: any; attributeToSor... Remove this comment to see the full error message
            filterBy={this.props.selectDay}
            // @ts-expect-error TS(2339) FIXME: Property 'attributeToSortBy' does not exist on typ... Remove this comment to see the full error message
            attributeToSortBy={this.props.attributeToSortBy}
            // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
            selectedDay={this.props.selectedDay}
            dates={dates}
          />
          <BreakdownContent
            // @ts-expect-error TS(2322) FIXME: Type '{ metrics: any; attributeToSortBy: any; sele... Remove this comment to see the full error message
            metrics={stories}
            // @ts-expect-error TS(2339) FIXME: Property 'attributeToSortBy' does not exist on typ... Remove this comment to see the full error message
            attributeToSortBy={this.props.attributeToSortBy}
            // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
            selectedDay={this.props.selectedDay}
            // @ts-expect-error TS(2339) FIXME: Property 'serviceId' does not exist on type 'Reado... Remove this comment to see the full error message
            serviceId={this.props.serviceId}
          />
        </div>
      )
    }

    return (
      <ChartCard>
        {/* @ts-expect-error TS(2741) FIXME: Property 'exporting' is missing in type '{ childre... Remove this comment to see the full error message */}
        <LockIfNotAllowed {...{ STORIES }}>
          <ChartHeader>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message */}
            <Helper label="instagram disclaimer">
              {/* @ts-expect-error TS(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
              <Title />
            </Helper>
            <AddReport
              chart="stories-breakdown"
              state={{
                // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
                attributeToSortBy: this.props.attributeToSortBy,
                // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
                selectedDay: this.props.selectedDay,
              }}
            />
          </ChartHeader>
          {content}
        </LockIfNotAllowed>
      </ChartCard>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
StoriesBreakdown.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
  ),
  attributeToSortBy: PropTypes.string.isRequired,
  selectedDay: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  sortBy: PropTypes.func.isRequired,
  selectDay: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
StoriesBreakdown.defaultProps = {
  stories: [],
  loading: false,
  fetch: () => ({}),
}

export default StoriesBreakdown
