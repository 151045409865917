import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Summary from '~/answers-summary'
import Chart from '~/answers-chart'
import BestTime from '~/best-time'
import Resources from '~/resources'

import { Profile } from '~/utils/types'

import { useLockInstagramLoginChannelContent } from '~/hooks/useLockInstagramLoginChannelContent'
import { InstagramFacebookReAuthMessage } from '../InstagramFacebookReAuthMessage'
import { MatchParams } from '~/utils/types'

const AnswersTab = ({
  match,
  selectedProfile,
}: {
  match: MatchParams
  selectedProfile: Profile
}): JSX.Element => {
  const { currentChannel, lockContent } =
    useLockInstagramLoginChannelContent(selectedProfile)

  if (lockContent && currentChannel) {
    return (
      <InstagramFacebookReAuthMessage
        channelId={currentChannel.id}
        name={currentChannel.name}
      />
    )
  }

  return (
    <Fragment>
      <Summary />
      {match.params.channel === 'instagram' && <BestTime />}
      <Chart metricType="day" />
      <Chart metricType="type" />
      <Chart metricType="frequency" />
      <Resources referral={'answers'} channel={match.params.channel} />
    </Fragment>
  )
}

export default connect()(AnswersTab)
