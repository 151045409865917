// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AppleIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'apple'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M16.4059 0.51101C16.3588 0.45876 14.6627 0.531635 13.1867 2.1225C11.7106 3.71199 11.9377 5.53523 11.9709 5.58198C12.0042 5.62873 14.0756 5.70161 15.3979 3.85224C16.7202 2.00288 16.453 0.564634 16.4059 0.51101ZM20.9946 16.6438C20.9281 16.5118 17.7753 14.9471 18.0689 11.9386C18.3624 8.93009 20.3881 8.10372 20.42 8.01435C20.4518 7.92497 19.5934 6.9281 18.6837 6.42348C18.0156 6.06805 17.2764 5.86423 16.5195 5.82673C16.3699 5.82261 15.8507 5.69611 14.7831 5.98623C14.0797 6.17736 12.4943 6.7961 12.0582 6.82085C11.6206 6.8456 10.3191 6.10311 8.91921 5.90648C8.02335 5.73461 7.07349 6.08661 6.39364 6.35748C5.71517 6.62698 4.42469 7.39422 3.5219 9.43334C2.61912 11.4711 3.09128 14.6996 3.42913 15.7033C3.76698 16.7071 4.29453 18.3488 5.19177 19.5478C5.98932 20.9008 7.04718 21.8399 7.48888 22.1589C7.93058 22.4779 9.17675 22.6896 10.0408 22.251C10.7358 21.8275 11.9903 21.5842 12.486 21.602C12.9803 21.6199 13.9551 21.8138 14.9534 22.3431C15.7441 22.614 16.4918 22.5013 17.2409 22.1988C17.9899 21.8949 19.0741 20.7427 20.3397 18.4065C20.8197 17.3203 21.038 16.7327 20.9946 16.6438Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
AppleIcon.displayName = 'AppleIcon'
export { AppleIcon }
