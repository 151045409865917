import { useSplit } from './useSplit'

export function useSplitEnabled<T = Record<string, never>>(
  name: string,
  attributes = {},
  enabledTreatment = 'on',
  defaultTreatment = 'off',
) {
  const { split, isReady, isTimedout, config, client } = useSplit<T>(
    name,
    attributes,
  )

  const defaultIsEnabled = defaultTreatment === enabledTreatment
  const flagIsEnabled = split.treatment === enabledTreatment

  // If there is no client it means that the SDK was not loaded so we consider it as ready
  // We early return with default values
  if (!client) {
    return {
      isEnabled: defaultIsEnabled,
      isReady: true,
      isTimedout: false,
      config: {},
      hasClient: false,
    }
  }

  const ready = isReady && !isTimedout
  return {
    isEnabled: ready ? flagIsEnabled : defaultIsEnabled,
    isReady,
    isTimedout,
    config: ready ? config : {},
    hasClient: true,
  }
}
