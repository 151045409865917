// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const ShapesIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'shapes'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M8.3 9.99999C8.17109 10.007 8.04274 9.97827 7.92916 9.91691C7.81557 9.85555 7.72116 9.76398 7.65637 9.65231C7.59159 9.54064 7.55894 9.41323 7.56205 9.28416C7.56517 9.1551 7.60391 9.02941 7.674 8.92099L11.4 2.99999C11.4585 2.89456 11.5433 2.80604 11.6462 2.74302C11.749 2.68 11.8663 2.64461 11.9869 2.64028C12.1074 2.63596 12.227 2.66284 12.334 2.71833C12.4411 2.77382 12.532 2.85603 12.598 2.95699L16.3 8.89999C16.373 9.00478 16.4159 9.12754 16.4241 9.25497C16.4323 9.3824 16.4055 9.50965 16.3466 9.62294C16.2877 9.73623 16.1989 9.83125 16.0899 9.89771C15.9808 9.96417 15.8557 9.99954 15.728 9.99999H8.3Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 14H4C3.44772 14 3 14.4477 3 15V20C3 20.5523 3.44772 21 4 21H9C9.55228 21 10 20.5523 10 20V15C10 14.4477 9.55228 14 9 14Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C15.567 14 14 15.567 14 17.5C14 19.433 15.567 21 17.5 21Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
)
ShapesIcon.displayName = 'ShapesIcon'
export { ShapesIcon }
