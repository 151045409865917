import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Text, Link } from '@buffer-mono/legacy-bufferapp-components'
import AudienceOverview from '~/demographic-overview'
import AudienceGenderAge from '~/demographic-gender-age'
import AudienceCities from '~/demographic-cities'
import AudienceCountries from '~/demographic-countries'
import ContentMask from '~/content-mask'
import { HC_UTM_PARAMS } from '../../../utils/constants'

import { ChartStateNoData as NoData, ChartCard } from '~/shared-components'
import { InstagramFacebookReAuthMessage } from '../InstagramFacebookReAuthMessage'

import { Profile, MatchParams } from '~/utils/types'
import { useLockInstagramLoginChannelContent } from '~/hooks/useLockInstagramLoginChannelContent'

const GridContainer = styled.div`
  position: relative;
  padding: 6rem 0.75rem 6.5rem 1rem;
`
const Header = styled.div`
  padding: 0;
  margin: 0;
`
const Description = styled.div`
  padding: 0 3rem;
  margin: 0;
  line-height: 1rem;
`

function isSupported(profileId: string | undefined, profiles: [Profile]) {
  const profile = profiles.find((p) => p.id === profileId)
  return profile ? profile.service !== 'twitter' : false
}

const AudienceTab = ({
  match,
  profiles,
  selectedProfile,
}: {
  match: MatchParams
  selectedProfile: Profile
  profiles: [Profile]
}): JSX.Element => {
  const { currentChannel, lockContent } =
    useLockInstagramLoginChannelContent(selectedProfile)

  if (lockContent && currentChannel) {
    return (
      <InstagramFacebookReAuthMessage
        channelId={currentChannel.id}
        name={currentChannel.name}
      />
    )
  }

  return (
    <Fragment>
      <ContentMask channel={match.params.channel}>
        {isSupported(match.params.id, profiles) && (
          <div>
            <AudienceOverview />
            <AudienceGenderAge />
            <AudienceCities />
            <AudienceCountries />
          </div>
        )}
      </ContentMask>
      {!isSupported(match.params.id, profiles) && (
        <ChartCard>
          <GridContainer id="js-dom-to-png-audience-summary">
            <NoData>
              <Header>
                <Text weight="medium" color="outerSpace">
                  Twitter demographics are currently not available.&nbsp;
                  <Link
                    // @ts-expect-error
                    href={`https://support.buffer.com/article/529-analyzing-your-audience?${HC_UTM_PARAMS}`}
                    newTab
                  >
                    Why?
                  </Link>
                </Text>
              </Header>
              <Description>
                <Text size="small">
                  Please choose a Facebook or Instagram account from the
                  dropdown
                </Text>
              </Description>
            </NoData>
          </GridContainer>
        </ChartCard>
      )}
    </Fragment>
  )
}

export default connect((state) => ({
  // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
  profiles: state.profiles ? state.profiles.socialProfiles : [],
}))(AudienceTab)
