// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const AutoResizeOnIcon = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'auto-resize-on'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          d="M21.3989 17.5029C21.9485 17.5029 22.3941 17.9504 22.3941 18.5024C22.3941 19.0544 21.9485 19.5019 21.3989 19.5019H18.4133V22.5005C18.4133 23.0525 17.9678 23.5 17.4181 23.5C16.8685 23.5 16.423 23.0525 16.423 22.5005V19.5019H7.46629C6.67447 19.5019 5.91509 19.186 5.35519 18.6237C4.79529 18.0613 4.48074 17.2986 4.48074 16.5034L4.48074 7.50771H1.49518C0.945558 7.50771 0.5 7.06021 0.5 6.50819C0.5 5.95617 0.945558 5.50867 1.49518 5.50867L4.48074 5.50867V2.51012C4.48074 1.9581 4.9263 1.5106 5.47592 1.5106C6.02555 1.5106 6.47111 1.9581 6.47111 2.51012V5.50867H9.45666C10.0063 5.50867 10.4518 5.95617 10.4518 6.50819C10.4518 7.06021 10.0063 7.50771 9.45666 7.50771H6.47111L6.47111 16.5034C6.47111 16.7685 6.57596 17.0227 6.76259 17.2101C6.94922 17.3976 7.20235 17.5029 7.46629 17.5029H16.423L16.423 14.5043C16.423 13.9523 16.8685 13.5048 17.4181 13.5048C17.9678 13.5048 18.4133 13.9523 18.4133 14.5043V17.5029H21.3989Z"
          fill="currentColor"
        />
        <path
          d="M21.6108 5.40236C22.8021 5.6717 22.7939 7.3544 21.6011 7.61286L21.5503 7.62238L21.4527 7.64414C20.7426 7.80163 20.0955 8.16386 19.5932 8.68511C19.0908 9.20636 18.7558 9.86326 18.6303 10.5729C18.4145 11.8026 16.6336 11.8026 16.4165 10.5729C16.2908 9.86073 15.954 9.20166 15.449 8.67944C14.944 8.15723 14.2935 7.79544 13.5803 7.64006L13.447 7.61286C12.2543 7.35304 12.246 5.6717 13.4374 5.40236L13.5927 5.36699C14.3041 5.20683 14.9517 4.84184 15.454 4.31801C15.9562 3.79418 16.2907 3.13493 16.4152 2.42329C16.6309 1.19222 18.4173 1.19222 18.633 2.42329C18.7568 3.13093 19.0882 3.78685 19.586 4.30933C20.0837 4.83181 20.7258 5.19775 21.4321 5.36155L21.5503 5.38876L21.6108 5.40236Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
AutoResizeOnIcon.displayName = 'AutoResizeOnIcon'
export { AutoResizeOnIcon }
