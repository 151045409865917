import { States } from '@bufferapp/ui'
import React from 'react'

const ContentMask = (args: {
  hasEnoughFollowers: boolean
  channel?: string
  children: any
}): JSX.Element => {
  const { hasEnoughFollowers, channel, children } = args

  if (hasEnoughFollowers) {
    return children
  }

  let description =
    'We need a minimum of 100 followers to get audience analytics for this profile.'
  if (channel === 'facebook') {
    description =
      'We need a minimum of 100 likes to get audience analytics for your Facebook page.'
  } else if (channel === 'instagram') {
    description =
      'We need a minimum of 100 followers to get audience analytics for your Instagram profile.'
  }

  return (
    <States>
      <States.Image
        src="https://buffer-analyze.s3.amazonaws.com/images/illustration-measurement.png"
        alt="a colourful illustration of a buffer character seeing their followers growing"
      />
      <States.Header>Almost there!</States.Header>
      <States.Description>{description}</States.Description>
    </States>
  )
}

export default ContentMask
