import clsx from 'clsx'
import React from 'react'
import { Slot } from '@radix-ui/react-slot'

import { Heading } from '../Heading'
import { HoverCard, HoverCardContentProps, HoverCardProps } from '../HoverCard'
import { Paragraph } from '../Paragraph'
import { UnstyledButton } from '../UnstyledButton'
import { VisuallyHidden } from '../VisuallyHidden'
import { CloseIcon } from '../icons'

import styles from './CoachMark.module.css'

type CoachMarkProps = Pick<HoverCardProps, 'children'> & {
  /**
   * Is it open by default? For uncontrolled usage
   * @default false
   */
  defaultOpen?: boolean
  /**
   * Use it to have precise control over the visibility of the coach mark
   */
  open?: boolean
  /**
   * Fired when visibility changes
   */
  onOpenChange?: (open: boolean) => void
  /**
   * Timeout after which coach mark will be dismissed automatically when closed,
   * set to `null` to disable this feature
   * @default 5_000
   */
  dismissTimeout?: number | null
  /**
   * Callback called when coach mark is dismissed
   */
  onDismiss?: () => void
  /**
   * Determines whether the CloseIcon should be displayed
   * @default true
   */
  showCloseIcon?: boolean
}

const CoachMarkContext = React.createContext<{
  dismiss: () => void
} | null>(null)

function useCoachMarkContext() {
  const context = React.useContext(CoachMarkContext)

  if (!context) {
    throw new Error(
      'CoachMark compound components cannot be rendered outside the CoachMark component',
    )
  }

  return context
}

/**
 * Use `CoachMark` to highlight new features or important parts of the UI. Please note that this is experimental component, use at your own risk.
 */
const CoachMark = ({
  dismissTimeout = 5_000,
  defaultOpen = false,
  open,
  onOpenChange,
  children,
  onDismiss,
  ...props
}: CoachMarkProps): JSX.Element => {
  const [readyToBeDismissed, setReadyToBeDismissed] = React.useState(false)

  const handleOpenChange = (newOpen: boolean) => {
    onOpenChange?.(newOpen)

    if (!newOpen && readyToBeDismissed) {
      onDismiss?.()
    }
  }

  const handleDismiss = () => {
    onDismiss?.()
  }

  // automatically mark as ready to be dismissed after timeout
  React.useEffect(() => {
    if (!open || dismissTimeout === null) {
      return
    }

    const dismissTimer = setTimeout(() => {
      setReadyToBeDismissed(true)
    }, dismissTimeout)

    return () => clearTimeout(dismissTimer)
  }, [open, dismissTimeout])

  return (
    <CoachMarkContext.Provider value={{ dismiss: handleDismiss }}>
      <HoverCard
        {...props}
        openDelay={100}
        closeDelay={100}
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={handleOpenChange}
      >
        {children}
      </HoverCard>
    </CoachMarkContext.Provider>
  )
}

CoachMark.displayName = 'CoachMark'

interface CoachMarkCustomStyle extends React.CSSProperties {
  '--spotlight-left'?: string
  '--spotlight-top'?: string
}

type CoachMarkSpotlightProps = React.ComponentPropsWithoutRef<'div'> & {
  color?: 'primary' | 'inverted'
  size?: 'small' | 'medium'
  style?: CoachMarkCustomStyle
}
/**
 * CoachMark spotlight, used to highlight the target element
 */
const CoachMarkSpotlight = React.forwardRef<
  React.ElementRef<'div'>,
  CoachMarkSpotlightProps
>(
  (
    { color = 'primary', size = 'medium', ...props }: CoachMarkSpotlightProps,
    forwardedRef,
  ) => {
    return (
      <HoverCard.Trigger>
        <div
          className={clsx(styles.spotlight, styles[color], styles[size])}
          data-spotlight
          data-testid="coachmark-spotlight"
          ref={forwardedRef}
          {...props}
        >
          <div className={styles.spotlightRing} />
          <div className={styles.spotlightRing} />
          <div className={styles.spotlightRing} />
          <div className={styles.spotlightRing} />
        </div>
      </HoverCard.Trigger>
    )
  },
)

CoachMarkSpotlight.displayName = 'CoachMark.Spotlight'

/**
 * CoachMark trigger wrapper
 */

type CoachMarkOverlayProps = {
  children: React.ReactNode
}

const CoachMarkOverlay = React.forwardRef<
  React.ElementRef<'div'>,
  CoachMarkOverlayProps
>(({ children }, forwardedRef) => {
  return (
    <div className={styles.overlay} ref={forwardedRef}>
      {children}
    </div>
  )
})

CoachMarkOverlay.displayName = 'CoachMark.Overlay'

/**
 * CoachMark content
 */

type CoachMarkContentProps = Pick<
  HoverCardContentProps,
  'children' | 'side' | 'align' | 'className'
>

const CoachMarkContent = React.forwardRef<
  React.ElementRef<typeof HoverCard.Content>,
  CoachMarkContentProps & { showCloseIcon?: boolean }
>((props, forwardedRef) => {
  const { dismiss } = useCoachMarkContext()
  const { showCloseIcon = true, ...restProps } = props

  return (
    <HoverCard.Content
      {...restProps}
      ref={forwardedRef}
      className={clsx(styles.content, props.className)}
    >
      {props.children}

      {showCloseIcon && (
        <UnstyledButton className={styles.dismiss} onClick={dismiss}>
          <VisuallyHidden>Dismiss</VisuallyHidden>
          <CloseIcon />
        </UnstyledButton>
      )}
    </HoverCard.Content>
  )
})

CoachMarkContent.displayName = 'CoachMark.Content'

/**
 * CoachMark desription
 */

interface CoachMarkDescriptionProps
  extends React.ComponentProps<typeof Paragraph> {
  children: React.ReactNode
  className?: string
}

const CoachMarkDescription = React.forwardRef<
  React.ElementRef<'p'>,
  CoachMarkDescriptionProps
>(({ className, children, ...restProps }, forwardedRef) => (
  <Paragraph
    className={clsx(styles.description, className)}
    ref={forwardedRef}
    {...restProps}
  >
    {children}
  </Paragraph>
))

CoachMarkDescription.displayName = 'CoachMark.Description'

/**
 * CoachMark title
 */

type CoachMarkTitleProps = {
  children: React.ReactNode
  className?: string
}

const CoachMarkTitle = React.forwardRef<
  React.ElementRef<'h1'>,
  CoachMarkTitleProps
>(({ className, children }, forwardedRef) => (
  <Heading
    size="small"
    className={clsx(styles.description, className)}
    ref={forwardedRef}
  >
    {children}
  </Heading>
))

CoachMarkTitle.displayName = 'CoachMark.Title'

/**
 * CoachMark footer
 */

type CoachMarkFooterProps = {
  children: React.ReactNode
  className?: string
}

const CoachMarkFooter = React.forwardRef<
  React.ElementRef<'div'>,
  CoachMarkFooterProps
>(({ children, className }, forwardedRef) => {
  return (
    <footer className={clsx(styles.footer, className)} ref={forwardedRef}>
      {children}
    </footer>
  )
})

CoachMarkFooter.displayName = 'CoachMark.Footer'

/**
 * CoachMark dismiss
 */

type CoachMarkDismissProps = {
  children: React.ReactNode
}

const CoachMarkDismiss = ({ children }: CoachMarkDismissProps): JSX.Element => {
  const { dismiss } = useCoachMarkContext()

  return <Slot onClick={dismiss}>{children}</Slot>
}

const CoachMarkObject = Object.assign(CoachMark, {
  Overlay: CoachMarkOverlay,
  Spotlight: CoachMarkSpotlight,
  Content: CoachMarkContent,
  Description: CoachMarkDescription,
  Title: CoachMarkTitle,
  Footer: CoachMarkFooter,
  Dismiss: CoachMarkDismiss,
})

export { CoachMarkObject as CoachMark }
export type { CoachMarkProps, CoachMarkContentProps }
