import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PostsTable from '~/posts-table'
import HashtagsTable from '~/hashtags-table'
import PostsSummaryTable from '~/posts-summary-table'
import BoostedPostsNotice from '~/boosted-posts-notice'

import { InstagramFacebookReAuthMessage } from '../InstagramFacebookReAuthMessage'

import { useLockInstagramLoginChannelContent } from '~/hooks/useLockInstagramLoginChannelContent'

import { Profile } from '~/utils/types'

const PostsTab = ({
  selectedProfile,
}: {
  selectedProfile: Profile
}): JSX.Element => {
  const { currentChannel, lockContent } =
    useLockInstagramLoginChannelContent(selectedProfile)

  if (lockContent && currentChannel) {
    return (
      <InstagramFacebookReAuthMessage
        channelId={currentChannel.id}
        name={currentChannel.name}
      />
    )
  }

  return (
    <Fragment>
      <BoostedPostsNotice />
      {/* @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'never'. */}
      <PostsSummaryTable />
      {/* @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'never'. */}
      <HashtagsTable />
      {/* @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'never'. */}
      <PostsTable />
    </Fragment>
  )
}

export default connect()(PostsTab)
