import { useSplitEnabled } from '@buffer-mono/features'

import { useAccount } from '~/account'
import type { Channel } from '../account/accountContext'
import type { Profile } from '~/utils/types'

export function useLockInstagramLoginChannelContent(selectedProfile: Profile): {
  currentChannel: Channel | undefined
  lockContent: boolean
} {
  const account = useAccount()

  // Compare against account query whose channels have access to apiVersion
  // in order to determine whether this channel is an Instagram Login channel.
  const currentChannel = account?.currentOrganization?.channels?.find(
    (channel) =>
      channel.id === selectedProfile.id &&
      channel.apiVersion === 'instagramLogin',
  )

  const isInstagramLoginChannel =
    currentChannel?.apiVersion === 'instagramLogin'

  const { isEnabled: isInstagramLoginEnabled } =
    useSplitEnabled('instagram-login')

  return {
    currentChannel,
    lockContent: isInstagramLoginEnabled && isInstagramLoginChannel,
  }
}
