// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'
const NotifyMePublished = React.forwardRef(
  (
    { size, stroke, className, color, ...props }: IconProps,
    ref: IconComponentRef,
  ) => {
    const iconName = 'notify-me-published'
    const computedSize = computeSize(size)
    const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          styles.base,
          color && textColorStyles[color],
        )}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={computedStrokeWidth}
        width={computedSize}
        height={computedSize}
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1875 16.95C9.1875 16.0387 9.94051 15.3 10.8694 15.3H10.8806C11.8095 15.3 12.5625 16.0387 12.5625 16.95C12.5625 17.8613 11.8095 18.6 10.8806 18.6H10.8694C9.94051 18.6 9.1875 17.8613 9.1875 16.95Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.05357 3.2C5.66086 3.2 5.25 3.5428 5.25 4.08V19.92C5.25 20.4572 5.66087 20.8 6.05357 20.8H15.6964C16.0891 20.8 16.5 20.4572 16.5 19.92V12.55C16.5 11.9425 17.0037 11.45 17.625 11.45C18.2463 11.45 18.75 11.9425 18.75 12.55V19.92C18.75 21.5698 17.434 23 15.6964 23H6.05357C4.31604 23 3 21.5698 3 19.92V4.08C3 2.43015 4.31604 1 6.05357 1H9.75C10.3713 1 10.875 1.49249 10.875 2.1C10.875 2.70751 10.3713 3.2 9.75 3.2H6.05357Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7071 2.29289C22.0976 2.68342 22.0976 3.31658 21.7071 3.70711L15 10.4142L11.2929 6.70711C10.9024 6.31658 10.9024 5.68342 11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289L15 7.58579L20.2929 2.29289C20.6834 1.90237 21.3166 1.90237 21.7071 2.29289Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)
NotifyMePublished.displayName = 'NotifyMePublished'
export { NotifyMePublished }
